import React, {Component} from 'react';

export default class ProjectsDescription extends Component {
    render() {
        return (
            <div className="projects-description">
                <div className="projects-description-row">
                    <div className="projects-description-col">
                        <div className="projects-description-item">
                            <h2>Experience</h2>
                            <p>
                                Here's an overview of my projects, achievements and education!
                            </p>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
