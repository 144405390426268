import React, {Component} from 'react';
import ReactTyped from "react-typed";

export default class Subtitle extends Component {
    render() {
        return (
            <div id="subtitle">
                <ReactTyped
                    className={'intro-subtitle'}
                    strings={[
                        "Where minds meet machines.",
                        "Cracking the code of cognition.",
                        "No to an AI-related catastrophe.",
                        "Can we emulate the brain?",
                        "Decode consciousness.",
                        "Smarter-than-human AI?",
                        "Artificial General Intelligence.",
                        "What about feelings?"]}
                    typeSpeed={70}
                    backSpeed={10}
                    loop
                />
            </div>
        );
    }
}
