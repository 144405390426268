import React, {Component} from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default class SkillBar extends Component {
    constructor(props) {
        super(props);
        this.state = {collapsed: true};
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({collapsed: false})
        }, 1000);
    }

    render() {
        const {collapsed} = this.state;
        const {hue, saturation, skills} = this.props;
        return (
            <div id="skillBar" className={`skill-container ${collapsed ? 'collapsed' : ''}`}>
                <h2>Skills</h2>

                <ul className="skills">
                    {skills.map((skill, index) =>
                        <ScrollAnimation animateIn={'load-skill-container'} animateOut={'unload-skill-container'} animateOnce={true} offset={50} duration={1}>
                            <li
                                key={skill.type}
                                style={{
                                    width: `calc(70px + ${skill.level}/100 * (100% - 70px))`,
                                    backgroundColor: `hsl(${hue}, ${saturation}%, ${70 - index * 2}%)`
                                }}
                            >
                                <p>{skill.type}<span>{skill.level}%</span></p>
                            </li>
                        </ScrollAnimation>
                    )}
                </ul>

            </div>
        )
    }
}
