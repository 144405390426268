import React, {Component} from 'react';

export default class ProfileText extends Component {
    render() {
        return (
            <div className="profile-text">
                <h2>About</h2>
                <span>
                     <p>
                         <p>
                             I am an enthusiastic computer science Master student with focus on topics
                             around <a target={'_blank'} rel={'noreferrer'} href={'https://en.wikipedia.org/wiki/Artificial_intelligence'}>AI</a>
                             , <a target={'_blank'} rel={'noreferrer'} href={'https://en.wikipedia.org/wiki/Cognitive_science'}>cognitive science</a>
                             , <a target={'_blank'} rel={'noreferrer'} href={'https://en.wikipedia.org/wiki/Psychology'}>psychology</a> and <a target={'_blank'} rel={'noreferrer'} href={'https://en.wikipedia.org/wiki/Social_science'}>social sciences</a> at
                             the <a target={'_blank'} rel={'noreferrer'} href={'https://www.lmu.de/en/index.html'}>LMU Munich</a>!
                             Plus, I am a music-loving guitarist and singer.
                         </p>
                         <p>
                             Check out my co-authored paper <a target={'_blank'} rel={'noreferrer'} href={'https://arxiv.org/abs/2409.20365'}>VideoINSTA: Zero-shot Long Video Understanding via Informative Spatial-Temporal Reasoning with LLMs</a> accepted
                             to <a target={'_blank'} rel={'noreferrer'} href={'https://2024.emnlp.org/'}>EMNLP 2024</a> and the
                             corresponding <a target={'_blank'} rel={'noreferrer'} href={'https://github.com/mayhugotong/VideoINSTA'}>framework for video reasoning on GitHub</a>.
                             This also corresponds to my Master’s thesis on the topic “Video Reasoning with Generative Large Language Models”.
                         </p>
                         <p>
                             Also check out the <a target={'_blank'} rel={'noreferrer'} href={'https://github.com/TemporalKGTeam/tkg-framework'}>framework for knowledge graph embeddings on GitHub</a> corresponding
                             to my Bachelor’s thesis on the topic "Investigating Temporal Knowledge Graph Embedding Models in a Unified Framework".
                         </p>
                         {/*<p>*/}
                         {/*    Additionally, I have gained a lot of coding experience at*/}
                         {/*    the <a target={'_blank'} rel={'noreferrer'} href={'https://www.doubleslash.de/'}>doubleSlash Net-Business GmbH</a> doing*/}
                         {/*    an internship as well as a working student job.*/}
                         {/*    Also, I have gained a lot of experiences founding an own company: Hollerkini.*/}
                         {/*</p>*/}
                     </p>
                    </span>
            </div>
        );
    }
}
