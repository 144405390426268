import React, {Component} from 'react';
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Popup from "reactjs-popup";

export default class ProjectsItems extends Component {
    render() {
        const white = 'rgb(255, 255, 255)';
        const blue = 'rgb(167,173,190)';

        const borderTop = '3px solid';
        const arrow = '7px solid ' + white;

        return (
            <div>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="October 2021 – March 2025"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Master's Degree Computer Science</h3>
                        <h4 className="vertical-timeline-element-subtitle">University of Munich</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Master's Degree in Computer Science at LMU</h3>
                            <p>
                                In order to pursue my passion further and to delve deeper into topics such as machine learning, I am naturally continuing my studies.
                            </p>
                            <p>
                                I already finished my Master's thesis and I am currently finishing my degree. I will probably graduate in March 2025.
                            </p>
                            <p>
                                Check out my co-authored
                                paper <a target={'_blank'} rel={'noreferrer'} href={'https://arxiv.org/abs/2409.20365'}>VideoINSTA: Zero-shot Long Video Understanding via Informative Spatial-Temporal Reasoning with LLMs</a> accepted
                                to <a target={'_blank'} rel={'noreferrer'} href={'https://2024.emnlp.org/'}>EMNLP 2024</a> and the corresponding <a target={'_blank'} rel={'noreferrer'} href={'https://github.com/mayhugotong/VideoINSTA'}>framework for video reasoning on GitHub</a>.
                                This also corresponds to my Master’s thesis on the topic “Video Reasoning with Generative Large Language Models”.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="October 2023 – March 2025"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Student Assistant</h3>
                        <h4 className="vertical-timeline-element-subtitle">University of Munich</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Tutoring Python for Beginners</h3>
                            <p>
                                As a tutor in Python for Beginners, I taught the basics of Python programming to first-semester students. I provided feedback and I worked to create a positive and inclusive learning environment that encouraged collaboration, creativity, and critical thinking. My goal was to help students develop the skills and knowledge they needed to succeed in the course and pursue their educational and career goals.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="February 2024 – July 2024"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Semester Abroad</h3>
                        <h4 className="vertical-timeline-element-subtitle">Monash University</h4>
                        <h5 className="projects-location">Melbourne, Australia</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Semester Abroad at Monash University in Melbourne</h3>
                            <p>
                                During my stay in Melbourne, Australia, I came in touch with lots of different cultures and people. I was able to improve my English skills and I learned a lot about the Australian way of life.
                                Moreover, the teaching style at <a href={"https://www.monash.edu/"} target={"_blank"} rel={"noreferrer"}>Monash University</a> was
                                very different from the one at the LMU. I was able to learn a lot of new
                                things and I am very grateful for this experience.
                                After my studies, I was able to travel around Australia and I was able to see many beautiful places, e.g. Tasmania, Sydney, Brisbane and Cairns.
                            </p>
                            <p>
                                It shaped me and my view of the world and I am very happy to have had this opportunity.
                                I can only recommend a semester abroad to everyone, because it is a unique experience that I will never forget.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="October 2020 – December 2023"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Software Development</h3>
                        <h4 className="vertical-timeline-element-subtitle">Freelance Work</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Freelance software development</h3>
                            <p>
                                Even though I was very satisfied with my work
                                at <a target={'_blank'} rel={'noreferrer'} href={'https://www.doubleslash.de/'}>doubleSlash
                                Net-Business GmbH</a>,
                                I still wanted to try something new.
                                As long as I study and such privileges are available to me,
                                I want to take full advantage of them.
                            </p>
                            <p>
                                I hope to be able to provide a digital perspective with smaller projects alongside my
                                studies, especially for smaller companies. Small businesses often lack digital
                                understanding or knowledge, but this can be counteracted.
                            </p>
                            <p>
                                In principle, however, I only support charitable and sustainable enterprises and
                                projects,
                                since nothing else makes sense in the current climate catastrophe situation.
                            </p>
                            <p>
                                <b>Here are some of my customers and the corresponding web projects:</b>
                                <ul>
                                    <li>Munich Strategy GmbH & Co. KG, <a href={"https://www.munich-strategy.com/"} target={"_blank"} rel={"noreferrer"}>https://www.munich-strategy.com/</a><br/>(Web Presence via WordPress, Deployment, SEO, Consulting)</li>
                                    <li>Rechtsanwaltskanzlei Burgmeier Brüseken Haußleiter, <a href={"https://muenchen-fachanwaelte.de/"} target={"_blank"} rel={"noreferrer"}>https://muenchen-fachanwaelte.de/</a><br/>(Web Presence via WordPress, Design, Deployment, SEO, Consulting)</li>
                                    <li>KATZE Gastwelt GmbH, <a href={"https://cafe-zinnowitz.de/"} target={"_blank"} rel={"noreferrer"}>https://cafe-zinnowitz.de/</a><br/>(Web Presence via WordPress, Design, Deployment, SEO, Consulting)</li>
                                    <li>Hollerkini, Wolfgang Scheitz & Max Erler GbR, <a href={"https://hollerkini.com/"} target={"_blank"} rel={"noreferrer"}>https://hollerkini.com/</a><br/>(Web Presence via WordPress, E-Commerce via WooCommerce, Design, Deployment, SEO, Consulting)</li>
                                    <li>Unknown Future Techno Collective, <a href={"https://unknown-future.de/"} target={"_blank"} rel={"noreferrer"}>https://unknown-future.de/</a><br/>(Web Presence via React (JavaScript), Design, Deployment, SEO, Consulting)</li>
                                    <li>Unknown Future Techno Collective, <a href={"https://shop.unknown-future.de/"} target={"_blank"} rel={"noreferrer"}>https://shop.unknown-future.de/</a><br/>(Web Shop via WordPress, Design, Deployment, SEO, Consulting)</li>
                                    <li>Nivo Käsevertrieb GbR, <a href={"https://nivo-kaesevertrieb.de/"} target={"_blank"} rel={"noreferrer"}>https://nivo-kaesevertrieb.de/</a><br/>(Web Presence via WordPress, Design, Deployment, SEO, Consulting)</li>
                                    <li>Max Ihlenburg Fotografie, <a href={"http://maxihlenburg.com/"} target={"_blank"} rel={"noreferrer"}>http://maxihlenburg.com/</a><br/>(Web Presence via WordPress, Design)</li>
                                    <li>Urinking, Maxharaj & Saeb GbR, <a href={"https://urinking.de/"} target={"_blank"} rel={"noreferrer"}>https://urinking.de/</a><br/>(Consulting SEO & IT-System)</li>
                                    <li>Bert Pollack Freiberufler, <a href={"https://bp-dwh.de/"} target={"_blank"} rel={"noreferrer"}>https://bp-dwh.de/</a><br/>(Web Presence via Hugo, Design)</li>
                                </ul>
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="July 2021 – December 2022"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Startup: Hollerkini</h3>
                        <h4 className="vertical-timeline-element-subtitle">Scheitz & Erler GbR</h4>
                        <h5 className="projects-location">Andechs, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Hollerkini – Elderberry liqueur from bavaria</h3>
                            <p>
                                <b>Warning: bavarian language and names may appear!</b>
                            </p>
                            <p>
                                Already in late summer 2016 and 2017 my best buddy Wolfi and I produced together with
                                his
                                neighbor Gerhard elderberry liqueur for our own use. Gerhard was in possession of a
                                traditional
                                recipe, which we optimized in 2018.
                            </p>
                            <p>
                                Out of a crazy idea the <i>Schnapsidee UG</i> and its trademark,
                                the <i>Hollerkini</i>, was born. We hired a fellow student for a label design and
                                created our first
                                website with <a target={'_blank'} rel={'noreferrer'}
                                                href={'https://de.wix.com/'}>Wix</a> (what fools we
                                were not
                                to use <a target={'_blank'} rel={'noreferrer'}
                                          href={'https://wordpress.org/'}>WordPress</a>).
                            </p>
                            <p>
                                Our friends, families and acquaintances
                                were thrilled and we drank <i>Hollerkini</i> at almost every festive occasion. Our next
                                milestone,
                                planned for spring 2021, is the official distribution.
                                Please visit <a target={'_blank'} rel={'noreferrer'} href={'https://hollerkini.com/'}>our
                                website</a> for
                                more
                                information (now <a target={'_blank'} rel={'noreferrer'}
                                                    href={'https://wordpress.org/'}>WordPress</a>)!
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="October 2021 – February 2022"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Student Assistant</h3>
                        <h4 className="vertical-timeline-element-subtitle">University of Munich</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Tutoring Software Development Practical Minor</h3>
                            <p>
                                As a tutor in a Tutoring Software Development Practical Minor, I provided one-on-one and small group support to students who needed help with understanding the course material, completing assignments, and preparing for exams. I worked closely with course instructors to ensure that I was familiar with the course content and objectives, and I designed and created instructional resources like tutorials and videos. I graded assignments and provided feedback to students, and I worked to create a positive and inclusive learning environment that encouraged collaboration, creativity, and critical thinking. My goal was to help students develop the skills and knowledge they needed to succeed in the course and pursue their educational and career goals.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="October 2017 – August 2021"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Bachelor's Degree Computer Science</h3>
                        <h4 className="vertical-timeline-element-subtitle">University of Munich</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Bachelor's Degree in Computer Science at LMU</h3>
                            <p>
                                Since my passion for computer science had already developed at school, the choice of the
                                course of studies was logical.
                                Even though I briefly thought about a technical business degree, because I like to get
                                out of myself,
                                I am communicative and a part of me is also a businessman, in the end I decided against
                                it, because I preferred to gain deep IT knowledge.
                            </p>
                            <p>
                                This was exactly the right decision! I feel very comfortable with my courses and find
                                all
                                lectures at the <a target={'_blank'} rel={'noreferrer'}
                                                   href={'https://www.uni-muenchen.de/index.html'}>LMU</a> very
                                interesting.
                            </p>
                            <p>
                                I am wrote my bachelor thesis on <i>Investigating Temporal Knowledge Graph Embedding
                                Models in a Unified Framework</i> and graduated in August 2021.
                            </p>
                            <h3>Investigating temporal knowledge graph embedding models in a unified framework</h3>
                            <p>
                                Research around knowledge graphs increased and gained importance in recent years.
                                Through application areas such as speech recognition, social networks and generally the
                                representation of relationships between entities in Big Data dimensions, link prediction
                                in particular is gaining more and more importance.
                            </p>
                            <br/>
                            <p>
                                My bachelor's thesis investigates the technical composition and experimental results of
                                a selection of state-of-the-art temporal knowledge graph embedding
                                models. After introducing the topic with its most important components, a detailed
                                overview of the models DE-SimplE, TComplEx, TNTComplEx,
                                ATiSE, TA-TransE and TA-DistMult is given regarding their modular techniques. Moreover,
                                in the context of a reproducibility study these models are
                                re-implemented into a unified framework – the TKGF – and tested to validate the reported
                                baseline results. The investigation results show that the availability
                                of official source codes is a key factor for a successful re-implementation and
                                validation. As the baseline results on the ICEWS14 dataset of DE-SimplE,
                                TComplEx, TNTComplEx and ATiSE could be reproduced while the baselines of the TA family
                                could not, this illustrates the importance of official implementations
                                since a reference implementation is available for all but the TA models. My thesis
                                additionally elaborates other problems in the related literature.
                            </p>
                            <br/>
                            <p>
                                In recent years, a lot of temporal knowledge graph embedding models have been developed
                                and it was my task to participate in the development of
                                an <a target={'_blank'} rel={'noreferrer'}
                                      href={'https://github.com/TemporalKGTeam/tkg-framework'}>
                                unified framework
                            </a> for these different models and then run experiments to evaluate the prior research
                                on them.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    {/*<VerticalTimelineElement*/}
                    {/*    className="vertical-timeline-element--work"*/}
                    {/*    contentStyle={{*/}
                    {/*        background: white,*/}
                    {/*        color: white,*/}
                    {/*        borderTop: borderTop + ' ' + blue*/}
                    {/*    }}*/}
                    {/*    contentArrowStyle={{borderRight: arrow}}*/}
                    {/*    date={t('months.may') + " 2020"}*/}
                    {/*    dateClassName={"projects-date"}*/}
                    {/*    iconStyle={{background: blue, color: white}}*/}
                    {/*>*/}
                    {/*    <h3 className="vertical-timeline-element-title">{t('azure_fundamentals.label')}</h3>*/}
                    {/*    <h4 className="vertical-timeline-element-subtitle">{t('certification.label')}</h4>*/}
                    {/*    <h5 className="projects-location">{t('munich.label')}</h5>*/}
                    {/*    <br/>*/}
                    {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                    {/*    <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>*/}
                    {/*        <h3>{t('azure_fundamentals.title')}</h3>*/}
                    {/*        <p>*/}
                    {/*            Since my mentor at <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                                  href={'https://www.doubleslash.de/'}>doubleSlash*/}
                    {/*            Net-Business GmbH</a> gave me the chance to participate in the Azure Fundamentals*/}
                    {/*            training at Microsoft, I seized the opportunity and passed the online exam.<br/>*/}
                    {/*            <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*            href={'documents/Microsoft_Certified_Professional_Certificate_0.pdf'}>Show certificate</a>.*/}
                    {/*        </p>*/}
                    {/*        <img src={"images/azure-fundamentals-600x600.png"}*/}
                    {/*             alt="Azure Fundamentals Certification"*/}
                    {/*             className={"azure-image"}*/}
                    {/*        />*/}
                    {/*    </Popup>*/}
                    {/*    <br/>*/}
                    {/*</VerticalTimelineElement>*/}
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="September 2019 – September 2020"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Working Student</h3>
                        <h4 className="vertical-timeline-element-subtitle">doubleSlash Net Business GmbH</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Working Student at doubleSlash Net-Business GmbH</h3>
                            <p>
                                After my instructive internship at <a target={'_blank'} rel={'noreferrer'}
                                                                      href={'https://www.doubleslash.de/'}>doubleSlash
                                Net-Business GmbH</a> I was asked if I would like to continue working there as a working
                                student.
                                This offer came in handy, because I loved the positive & constructive working
                                atmosphere, and thus had the opportunity to gain valuable experience and earn some extra
                                money while studying.
                            </p>
                            <p>
                                This year I learned to work independently and to actively participate in project
                                management.
                                Through various topics, from automation & pipeline management to microservices and cloud
                                technologies, I have gained very broad knowledge.
                            </p>
                            <p>
                                I have also learned to appreciate an all-round positive and friendly working atmosphere,
                                as communication at // is a stark contrast to the tone of the lecturers at the
                                university.
                            </p>
                            <p>
                                I am glad to have gained this valuable experience and to know that digitalization is also
                                in good hands.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="April 2020 – August 2020"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">COVID-19 Volunteer Matching</h3>
                        <h4 className="vertical-timeline-element-subtitle">Scheitz & Völkl & Erler GbR</h4>
                        <h5 className="projects-location">Herrsching, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <div className={'popup-scrollable'}>
                                <h3>COVID-19 Volunteer Matching System: "Herrsching hilft."</h3>
                                <p>
                                    <i>Herrsching hilft</i> is a web project of Thomas Völkl, Wolfgang Scheitz and me.
                                </p>
                                <p>
                                    Because of the <a target="_blank" rel={'noreferrer'}
                                                      href={'https://de.wikipedia.org/wiki/COVID-19-Pandemie'}>COVID-19
                                    pandemic</a>, solidarity is needed in society.
                                    Risk groups must be protected and supported.
                                </p>
                                <p>
                                    <i>Herrsching hilft</i> is an approach to match people who need help in their daily
                                    life with people who can help (like students, pupils or other volunteers).
                                    The idea was born at the end of March 2020, when Germany was in lockdown and the
                                    university semester break began.
                                    Thomas and I were responsible for the technical planning and implementation, Wolfi
                                    for marketing.
                                    We provided a frontend using <a target="_blank" rel={'noreferrer'}
                                                                    href={'https://wordpress.org/'}>WordPress</a> and a
                                    backend using Java and <a target="_blank" rel={'noreferrer'}
                                                              href={'https://spring.io/projects/spring-boot'}>Spring
                                    Boot</a>.
                                </p>
                                <p>
                                    At the end of April, all requirements for the project were met and we started the
                                    test phase.
                                    Wolfi spoke with the local authorities in Herrsching and Thomas and I tested the
                                    application with various test data and scenarios.
                                    Everything went as planned, so we went online in May with the domain
                                    herrschinghilft.de.
                                    Sadly, we all had too much to do with our studies so we could not put enough energy
                                    into the marketing and the registrations for
                                    <i> Hersching hilft</i> as well as the matchings were rare.
                                </p>
                                <p>
                                    So in August we made the sad decision to stop the project and go offline.
                                    However, the experience and knowledge we gained cannot go offline and we all left
                                    the project better and stronger!
                                </p>
                            </div>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="March 2019 – September 2019"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Internship</h3>
                        <h4 className="vertical-timeline-element-subtitle">doubleSlash Net Business GmbH</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Internship at doubleSlash Net-Business GmbH</h3>
                            <p>
                                In the context of the computer science studies at the LMU, little practice is taught,
                                which is why I decided for a voluntary internship at <a target={'_blank'}
                                                                                        rel={'noreferrer'}
                                                                                        href={'https://www.doubleslash.de/'}>doubleSlash
                                Net-Business GmbH</a>.
                                As it quickly turned out, this decision was great!
                            </p>
                            <p>
                                I learned a lot about business software development in a very short time and got to know
                                an extremely friendly team and working atmosphere.
                                With a project called slashPark my internship supervisor offered me the opportunity to
                                set up an IoT project from A to Z (Device, Hub, Cloud, App).
                                I developed an important understanding of various IT components such as operating
                                systems (<a target={'_blank'}
                                            rel={'noreferrer'}
                                            href={'https://docs.microsoft.com/de-de/windows/iot-core/windows-iot'}>Windows
                                10 IoT</a>
                                , <a target={'_blank'} rel={'noreferrer'}
                                     href={'https://www.gnu.org/home.de.html'}>GNU</a>, <a
                                target={'_blank'} rel={'noreferrer'} href={'https://www.raspberrypi.org/software/'}>Raspberry
                                OS</a> etc.),
                                IoT protocols (<a target={'_blank'}
                                                  rel={'noreferrer'}
                                                  href={'https://lora-alliance.org/about-lorawan'}>LoRaWAN</a>),
                                cloud technologies (<a target={'_blank'}
                                                       rel={'noreferrer'}
                                                       href={'https://azure.microsoft.com/de-de/services/iot-hub/'}>Azure
                                IoT Hub</a>
                                , <a target={'_blank'}
                                     rel={'noreferrer'}
                                     href={'https://azure.microsoft.com/de-de/services/functions/'}>Azure
                                Functions</a>, <a target={'_blank'}
                                                  rel={'noreferrer'}
                                                  href={'https://azure.microsoft.com/de-de/services/storage/'}>Azure
                                Storage</a>)
                                and of course software development (C#, Python, Java).
                            </p>
                            <p>
                                After that I mainly programmed in Java for various projects and was more and more
                                integrated into the real project environment.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    {/*<VerticalTimelineElement*/}
                    {/*    className="vertical-timeline-element--work"*/}
                    {/*    contentStyle={{*/}
                    {/*        background: white,*/}
                    {/*        color: white,*/}
                    {/*        borderTop: borderTop + ' ' + blue*/}
                    {/*    }}*/}
                    {/*    contentArrowStyle={{borderRight: arrow}}*/}
                    {/*    date={t('months.october') + " 2018 – " + t('months.march') + " 2019"}*/}
                    {/*    dateClassName={"projects-date"}*/}
                    {/*    iconStyle={{background: blue, color: white}}*/}
                    {/*>*/}
                    {/*    <h3 className="vertical-timeline-element-title">{t('armadillokub.label')}</h3>*/}
                    {/*    <h4 className="vertical-timeline-element-subtitle">University of Munich</h4>*/}
                    {/*    <h5 className="projects-location">{t('munich.label')}</h5>*/}
                    {/*    <br/>*/}
                    {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                    {/*    <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">{t('read_more.label')}</a>} modal>*/}
                    {/*        <h3>{t('armadillokub.title')}</h3>*/}
                    {/*        <p>*/}
                    {/*            Even if I can't emphasize enough that the computer science studies at the LMU don't*/}
                    {/*            provide enough practice,*/}
                    {/*            the software development internship was an exception. This was not an internship at any*/}
                    {/*            company, but a regular*/}
                    {/*            course that was supposed to teach you the basics of practical software development.*/}
                    {/*            There were four projects*/}
                    {/*            scheduled for the semester, three individual projects and one group project.*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            In the individual projects, great importance was attached to basics and the own*/}
                    {/*            implementation of common data*/}
                    {/*            structures, such as a <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                                     href={'https://en.wikipedia.org/wiki/Merkle_tree'}>Merkle*/}
                    {/*            tree</a> (known from blockchain) and <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                                                    href={'https://de.wikipedia.org/wiki/Ameise_(Turingmaschine)'}>langton's*/}
                    {/*            ant</a> (turing machine).*/}
                    {/*        </p>*/}
                    {/*        <p>*/}
                    {/*            The last project was the group project where my team had to program a network-compatible*/}
                    {/*            multiplayer game: <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                                 href={'https://en.wikipedia.org/wiki/Rummikub'}>Rummikub</a>.*/}
                    {/*            Our team was given the name Armadillo, so we called our project Armadillokub.*/}
                    {/*        </p>*/}
                    {/*    </Popup>*/}
                    {/*    <br/>*/}
                    {/*</VerticalTimelineElement>*/}
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{
                            background: white,
                            color: white,
                            borderTop: borderTop + ' ' + blue
                        }}
                        contentArrowStyle={{borderRight: arrow}}
                        date="September 2015 – May 2017"
                        dateClassName={"projects-date"}
                        iconStyle={{background: blue, color: white}}
                    >
                        <h3 className="vertical-timeline-element-title">Abitur (High School Graduation)</h3>
                        <h4 className="vertical-timeline-element-subtitle">Max-Planck-Gymnasium</h4>
                        <h5 className="projects-location">Munich, Germany</h5>
                        <br/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">More information</a>} modal>
                            <h3>Abitur (High School Graduation) at MPG</h3>
                            <p>
                                The time in the upper school of the <a target={'_blank'} rel={'noreferrer'}
                                                                       href={'https://www.mpg-muenchen.de'}>MPG</a> was
                                easy for me and I was very happy that I decided
                                to study computer science. I had a very cool teacher whose heart was beating for
                                computer science and who inspired the whole course for it.
                            </p>
                            <p>
                                Of course, during my school days I was mainly busy with individual orientation and big
                                parties, but my enthusiasm for computer science and hacking has always been
                                trend-setting.
                            </p>
                            <p>
                                The MPG was really a diverse school. There were seminar and leisure activities like the
                                school beekeeping,
                                the school brewery "Planck's Gold", the school garden and much more. Furthermore,
                                students and teachers were
                                always friendly and proportionate. It is an honor to have been part of such a diverse
                                and tolerant community.
                            </p>
                        </Popup>
                        <br/>
                    </VerticalTimelineElement>
                    {/*<VerticalTimelineElement*/}
                    {/*    className="vertical-timeline-element--work"*/}
                    {/*    contentStyle={{*/}
                    {/*        background: white,*/}
                    {/*        color: white,*/}
                    {/*        borderTop: borderTop + ' ' + blue*/}
                    {/*    }}*/}
                    {/*    contentArrowStyle={{borderRight: arrow}}*/}
                    {/*    date={t('months.september') + " 2015 – " + t('months.march') + " 2017"}*/}
                    {/*    dateClassName={"projects-date"}*/}
                    {/*    iconStyle={{background: blue, color: white}}*/}
                    {/*>*/}
                    {/*    <h3 className="vertical-timeline-element-title">{t('game_programming.label')}</h3>*/}
                    {/*    <h4 className="vertical-timeline-element-subtitle">Max-Planck-Gymnasium</h4>*/}
                    {/*    <h5 className="projects-location">{t('munich.label')}</h5>*/}
                    {/*    <br/>*/}
                    {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                    {/*    <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">{t('read_more.label')}</a>} modal>*/}
                    {/*        <h3>{t('game_programming.title')}</h3>*/}
                    {/*        <p>*/}
                    {/*            As made for me, the scientific seminar <i>game programming</i> was offered for my upper*/}
                    {/*            school time*/}
                    {/*            at the <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                      href={'https://www.mpg-muenchen.de'}>MPG</a>.*/}
                    {/*            With a thoroughly chill out teacher we learned how to use <a target={'_blank'}*/}
                    {/*                                                                         rel={'noreferrer'}*/}
                    {/*                                                                         href={'https://openjfx.io/'}>JavaFX</a> and*/}
                    {/*            the <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                   href={'https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93controller'}>MVC*/}
                    {/*            pattern</a>.*/}
                    {/*            For the successful completion we had to program a running game and document our approach*/}
                    {/*            in the corresponding scientific paper.*/}
                    {/*        </p>*/}
                    {/*    </Popup>*/}
                    {/*    <br/>*/}
                    {/*</VerticalTimelineElement>*/}
                    {/*<VerticalTimelineElement*/}
                    {/*    className="vertical-timeline-element--work"*/}
                    {/*    contentStyle={{*/}
                    {/*        background: white,*/}
                    {/*        color: white,*/}
                    {/*        borderTop: borderTop + ' ' + blue*/}
                    {/*    }}*/}
                    {/*    contentArrowStyle={{borderRight: arrow}}*/}
                    {/*    date={t('months.july') + " 2015"}*/}
                    {/*    dateClassName={"projects-date"}*/}
                    {/*    iconStyle={{background: blue, color: white}}*/}
                    {/*>*/}
                    {/*    <h3 className="vertical-timeline-element-title">{t('internship.label')}</h3>*/}
                    {/*    <h4 className="vertical-timeline-element-subtitle">Fabrikkinder Pasinger Fabrik</h4>*/}
                    {/*    <h5 className="projects-location">{t('munich.label')}</h5>*/}
                    {/*    <br/>*/}
                    {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                    {/*    <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">{t('read_more.label')}</a>} modal>*/}
                    {/*        <h3>{t('internship_fabrikkinder.title')}</h3>*/}
                    {/*        <p>*/}
                    {/*            <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*               href={'http://www.fabrikkinder.de/'}>Fabrikkinder</a> is an*/}
                    {/*            afternoon*/}
                    {/*            care for primary school children. As part of the obligatory*/}
                    {/*            social internship in the 10th grade, I spent a week there to look after the pupils and*/}
                    {/*            work with them in a playful and pedagogical way. I take great pleasure in being a role*/}
                    {/*            model*/}
                    {/*            and teaching others (not only children).*/}
                    {/*        </p>*/}
                    {/*    </Popup>*/}
                    {/*    <br/>*/}
                    {/*</VerticalTimelineElement>*/}
                    {/*<VerticalTimelineElement*/}
                    {/*    className="vertical-timeline-element--work"*/}
                    {/*    contentStyle={{*/}
                    {/*        background: white,*/}
                    {/*        color: white,*/}
                    {/*        borderTop: borderTop + ' ' + blue*/}
                    {/*    }}*/}
                    {/*    contentArrowStyle={{borderRight: arrow}}*/}
                    {/*    date={t('months.february') + " 2014"}*/}
                    {/*    dateClassName={"projects-date"}*/}
                    {/*    iconStyle={{background: blue, color: white}}*/}
                    {/*>*/}
                    {/*    <h3 className="vertical-timeline-element-title">{t('internship.label')}</h3>*/}
                    {/*    <h4 className="vertical-timeline-element-subtitle">BMW M</h4>*/}
                    {/*    <h5 className="projects-location">{t('munich.label')}</h5>*/}
                    {/*    <br/>*/}
                    {/*    /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/*/}
                    {/*    <Popup className={'blue-popup'} trigger={<a className="projects-more-info-trigger">{t('read_more.label')}</a>} modal>*/}
                    {/*        <h3>{t('internship_bmw.title')}</h3>*/}
                    {/*        <p>*/}
                    {/*            As part of the obligatory practical training in the 9th grade, I spent a week in the*/}
                    {/*            test*/}
                    {/*            engine workshop of <a target={'_blank'} rel={'noreferrer'}*/}
                    {/*                                  href={'https://www.bmw-m.com/de/index.html'}>BMW*/}
                    {/*            M</a>.*/}
                    {/*            At the sports car manufacturer I got to know a lot about mechanical engineering.*/}
                    {/*            However, my enthusiasm for it was limited. I was much more interested in the tools*/}
                    {/*            behind it,*/}
                    {/*            for example 3D software.*/}
                    {/*        </p>*/}
                    {/*    </Popup>*/}
                    {/*    <br/>*/}
                    {/*</VerticalTimelineElement>*/}
                </VerticalTimeline>
            </div>
        );
    }
}
