import React, {Component} from 'react';

export default class ProfileImage extends Component {
    render() {
        return (
            <div className="profile-image-container">
                <div className={'flip-card flip-card-profile'}>
                    <div className={'flip-card-inner'}>
                        <div className={'flip-card-front'}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            {/*<img className={'profile-image'} src={"images/max-erler-in-suit.jpeg"} alt={"My profile picture."}/>*/}
                            <img className={'profile-image'} src={"images/profile_kangaroo.JPEG"} alt={"My profile picture."}/>
                        </div>
                        <div className={'flip-card-back-profile'}>
                            <p>
                                <span className="profile-title">Profile:</span><br className={"invisible"} />
                                <span>AI Research</span>
                                <br/>
                                <br className={"invisible"} />
                                <span className="profile-title">Location:</span><br className={"invisible"} />
                                <span>Munich, Germany</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
