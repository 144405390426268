import React, {Component} from 'react';
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <div className={'footer'}>
                &#169;&#160;
                Copyright Max Erler 2024
                &#160;&#160;
                <Link to={'/imprint'} target={'_blank'} rel={'nofollow'}>Imprint</Link>
                &#160;&#160;
                <Link to={'/privacy'} target={'_blank'} rel={'nofollow'}>Privacy</Link>
            </div>
        );
    }
}
